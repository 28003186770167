<template>
    <main>
         <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="coming-soon">
                        <h1>Coming Soon</h1>
                    </div>
                </div>
            </div>
        </div>
    </main>    
</template>
<script>
export default {
    
}
</script>